import { useNavigation } from '@remix-run/react';
import { useEffect, useState } from 'react';
import getNavigation from '~/utilities/remix/getNavigation';

function useProcessing() {
	const [wasSubmitting, setWasSubmitting] = useState(false);
	const [isSubmissionComplete, setIsSubmissionComplete] = useState(false);

	const navigation = getNavigation(useNavigation());

	useEffect(() => {
		if (navigation.isSubmission) {
			setWasSubmitting(true);
		}

		if (wasSubmitting && navigation.isIdle) {
			setIsSubmissionComplete(true);
		}
	}, [navigation.isIdle, navigation.isSubmission, setWasSubmitting, wasSubmitting]);

	return {
		...navigation,
		isSubmissionComplete,
	};
}

export { useProcessing as default, useProcessing };
