/* eslint-disable no-underscore-dangle */
import { useNavigation } from '@remix-run/react';

function getNavigation(navigation: ReturnType<typeof useNavigation>) {
	const isGetRequest = navigation.formMethod !== undefined && navigation.formMethod === 'GET';
	const samePage = navigation.formAction === (navigation.location && `${navigation.location.pathname}${navigation.location.search}`);
	const isFetcherActionRedirect = () => {
		const state = navigation.location?.state;
		const _isRedirect = (state && '_isRedirect' in state);
		const _isFetchActionRedirect = (state && '_isFetchActionRedirect' in state);

		return Boolean(_isRedirect && _isFetchActionRedirect);
	};

	/**
	 * Actions
	 */
	const _isSubmission = () => navigation.state === 'submitting' && !isGetRequest;
	const _isRefresh = () => navigation.state === 'loading' && !isGetRequest && samePage;
	const _isSubmissionRedirect = () => navigation.state === 'loading' && !isGetRequest && !samePage;

	/**
	 * Loaders
	 */
	const _isLoading = () => (
		navigation.state === 'loading' && (navigation.formMethod === undefined || navigation.formMethod === 'GET')
	);

	const _isLoadingRedirect = () => navigation.state === 'loading' && isGetRequest && !samePage;

	/**
	 * Catch all
	 */
	const _isProcessing = () => navigation.state !== 'idle';

	return {
		isIdle: navigation.state === 'idle',
		/**
		 * Actions
		 */
		isSubmission: _isSubmission(),
		isRefresh: _isRefresh(),
		isSubmissionRedirect: _isSubmissionRedirect(),

		/**
		 * Loaders
		 */
		isLoading: _isLoading(),
		isLoadingRedirect: _isLoadingRedirect(),

		/**
		 * Catch all
		 */
		isProcessing: _isProcessing() && !isFetcherActionRedirect(),
	};
}

export { getNavigation as default, getNavigation };
